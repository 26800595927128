import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import {FiHome, FiSettings} from 'react-icons/fi';
import {PiBagFill, PiStrategyBold} from "react-icons/pi";
import {TbPresentationAnalytics} from "react-icons/tb";
import {MdCandlestickChart} from "react-icons/md";
import Sidebar from '../../components/XCapitalTerminal/layoutComponents/Sidebar';
import Header from '../../components/XCapitalTerminal/layoutComponents/Header';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken, load_user, isTokenExpired } from '../../actions/auth';

const XCapitalTerminalLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.user?.role);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const access = useSelector((state) => state.auth.access);
  const first_name =useSelector(state=>state.auth.user?.first_name)
  // const checkUser=useSelector(state=>state)

  // Shared Sidebar Items
  // const sidebarItems = [
  //   { name: 'Dashboard', icon: 'FiHome', link: '/client/x-capital-terminal/dashboard' },
  //   { 
  //     name: 'Market analysis', 
  //     icon: 'TbPresentationAnalytics',
  //     subMenu: [
  //       { name: 'Stock Markets', link: '/client/x-capital-terminal/market-analysis/stock-market' },
  //       { name: 'Bond Markets', link: '/client/x-capital-terminal/market-analysis/bond-market' },
  //       { name: 'Derivatives Markets', link: '/client/x-capital-terminal/market-analysis/derivative-market' },
  //       { name: 'Forex Market', link: '/client/x-capital-terminal/market-analysis/forex-market' },
  //       { name: 'Commodities Markets', link: '/client/x-capital-terminal/market-analysis/commodities-market' },
  //     ],
  //   },
  //   { name: 'Portfolio management', icon: 'PiBagFill', link: '/client/x-capital-terminal/portfolio-management' },
  //   { name: 'Charting', icon: 'MdCandlestickChart', link: '/client/x-capital-terminal/charting' },
  //   { name: 'Quantitative strategies', icon: 'PiStrategyBold', link: '/client/x-capital-terminal/quantitative' },
  //   { name: 'Settings', icon: 'FiSettings', link: '/client/x-capital-terminal/account-settings' },
  // ];
  const sidebarItems = [
    { name: 'Dashboard', icon: FiHome, link: '/client/x-capital-terminal/dashboard' },
    { name: 'Market analysis', icon: TbPresentationAnalytics,
      subMenu: [
        { name: 'Stock Markets', link: '/client/x-capital-terminal/market-analysis/stock-market' },
        { name: 'Bond Markets' , link: '/client/x-capital-terminal/market-analysis/bond-market'},
        { name: 'Derivatives Markets', link: '/client/x-capital-terminal/market-analysis/derivative-market' },
        { name: 'Forex Market', link: '/client/x-capital-terminal/market-analysis/forex-market' },
        { name: 'Commodities Markets' },
      ],

     },
    { 
      name: 'Portfolio management', 
      icon: PiBagFill, link: '/client/x-capital-terminal/portfolio-management'
    },
    { name: 'Charting', icon: MdCandlestickChart, link: '/client/x-capital-terminal/charting'},
    { name: 'Quantitative strategies', icon: PiStrategyBold, link: '/client/x-capital-terminal/quantitative' },
    { name: 'Settings', icon: FiSettings, link: '/client/x-capital-terminal/account-settings' },
  ];
  // Authentication and Token Refresh Logic
  useEffect(() => {
    const initializeAuth = async () => {
      if (access && isTokenExpired(access)) {
        await dispatch(refreshToken());
      }
      dispatch(load_user());
    };

    initializeAuth();

    const refreshInterval = setInterval(() => {
      if (access && isTokenExpired(access)) {
        dispatch(refreshToken());
      }
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    return () => clearInterval(refreshInterval);
  }, [dispatch, access]);

  // Redirect Users Based on Authentication and Role
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else if (role && !['Admin', 'Manager', 'Client'].includes(role)) {
      // navigate('/not-access');
      console.log("not access from XTLayout")
    }
  }, [isAuthenticated, role, navigate]);

  if (!isAuthenticated || !role) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box id="main-layout" bgColor="black" as="main" minH="100vh">
      {/* <Sidebar items={sidebarItems} username="John Doe" /> */}
      <Sidebar items={sidebarItems} username={first_name} />
      <Header />
      <Box as="main" minH="80vh" width="80%" ml="15%">
        <Outlet />
      </Box>
    </Box>
  );
};

export default XCapitalTerminalLayout;
