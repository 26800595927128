import React, { useState, useRef, useEffect } from 'react';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
// import FailedLoginModal from '../components/FailedLoginModal';
import { connect, useSelector } from 'react-redux';
import { login } from '../../actions/auth'; 
import { LOGIN_SUCCESS } from '../../actions/types'; 
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  Image,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from 'react-icons/hi';
import xcapital_logo from "../../assets/logo/logo.svg"

const LoginPage = ({ login, isAuthenticated, user }) => {
  const bg = "yellow.500"
  const bg2 = "black"
  const colorH = "white"
  const colorP = "blue"
  const grayScales = "gray"
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const mergeRef = useMergeRefs(inputRef);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  const [loginError, setLoginError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  useEffect(() => {
    const rememberedCheck = localStorage.getItem('rememberMe');

    if (rememberedCheck === 'true') {
      setRememberMe(true);
    }
  }, []);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    const res = await login(email, password);

    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      sessionStorage.setItem('rememberMe', 'true');
    } else {
      sessionStorage.setItem('rememberMe', 'true');
    }

    if (res === LOGIN_SUCCESS) {
      setLoginError(false);
    } else {
      setLoginError(true);
      setTimeout(() => {
        setLoginError(false);
      }, 4000);
    }
  };

  if (isAuthenticated) {
    if (user && user.role) 
      return <Navigate to={`/client/x-capital-terminal/dashboard`} />
      // return <Navigate to={`/x-capital/terminal/${user.role}`} />
  }

  return (
      <Box backgroundColor={"#0c0c0c"} height="100vh">
        <Box pb="60px"/>
            <Box
              width={{ base: "90%", sm: "70%", md: "50%", lg: "30%" }} 
              m="auto" 
              p={"0 20px 60px 20px"} 
              borderWidth="2px" 
              borderRadius="5px"
            >
              <Box>
                  <Image src={xcapital_logo} m="auto" h="150px" w="150px"/>
              </Box>
              <Text color="white" fontSize={{ base: "24px", md: "36px" }} textAlign="center">
                  Welcome !
              </Text>
              <Text color={"#ffce30"} fontSize={{ base: "18px", md: "24px" }} textAlign="center" fontWeight="bold">
                  Login to X Capital Terminal
              </Text>
              <form onSubmit={onSubmit}>
                  <Stack spacing="5" color="white">
                    <FormControl>
                      <FormLabel
                        htmlFor="email"
                        color="white"
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight="500"
                      >
                        E-mail
                      </FormLabel>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Email Address"
                        border="1px solid #7F989A"
                        focusBorderColor="#E7EBEE"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        required
                        fontSize={{ base: "14px", md: "16px" }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="password"
                        color="#7F989A"
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight="500"
                      >
                        Password
                      </FormLabel>
                      <InputGroup>
                        <InputRightElement>
                          <Divider
                            orientation="vertical"
                            height="25px"
                            border="solid 1px #CFD9E0"
                          />
                          <IconButton
                            variant="text"
                            aria-label={
                              isOpen ? "Mask password" : "Reveal password"
                            }
                            // color="#7F989A"
                            icon={isOpen ? <HiEyeOff /> : <HiEye />}
                            onClick={onClickReveal}
                          />
                        </InputRightElement>
                        <Input
                          id="password"
                          ref={mergeRef}
                          placeholder="Password"
                          name="password"
                          type={isOpen ? "text" : "password"}
                          autoComplete="current-password"
                          value={password}
                          onChange={(e) => onChange(e)}
                          required
                          fontSize={{ base: "14px", md: "16px" }}
                          mb={10}
                        />
                      </InputGroup>
                    </FormControl>
                  </Stack>
                  <HStack justify="space-between">
                    {/*<Checkbox
                      isChecked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      colorScheme="blackAlpha"
                      size="md"
                      iconColor="white"
                      borderColor="black"
                    >
                      <Text color="black" fontSize={{ base: "14px", md: "16px" }}>
                        Remember me
                      </Text>
                    </Checkbox>
                     <Link
                      variant="text"
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight="500"
                      to="/reset-password"
                      color={"#ffce30"}
                      as={RouterLink}
                    >
                      Forgot password?
                    </Link> */}
                  </HStack>
                    <Button
                      backgroundColor="#393939"
                      type="submit"
                      color="white"
                      _hover={{backgroundColor: "#ffce30", color: "#000"}}
                      width="100%"
                    >
                      Sign in
                    </Button>
                     {/* <Box textAlign="center">
                         <Link color={"#ffce30"} fontSize="12px" textDecoration="underline">
                             Forgot password?
                         </Link>
                     </Box> */}
                    {loginError && (
                      // <FailedLoginModal
                      //   isOpen={loginError}
                      //   onClose={() => setLoginError(false)}
                      //   title="Login not successful"
                      //   message="Your email or password are incorrect."
                      // />
                      <p>
                        Login not successful. Your email or password are incorrect.
                      </p>
                    )}
              </form>
            </Box>
      </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

// const mapStateToProps = (state) => {
//   console.log("state", state); // Inspect the auth state
//   return {
//     isAuthenticated: state.auth.isAuthenticated,
//     user: state.auth.user,
//   };
// };
export default connect(mapStateToProps, { login })(LoginPage);










// import React from "react";
// import {useNavigate} from "react-router-dom";
// import {Box, Button, FormControl, FormLabel, Image, Input, Link, Text} from "@chakra-ui/react";
// import xcapital_logo from "../../assets/logo/logo.svg"

// function LoginPage() {
//     const navigate = useNavigate();

//     return (
//         <Box backgroundColor={"#0c0c0c"} height="100vh">
//             <Box pb="60px"/>
//             <Box 
//                 width={{ base: "90%", sm: "70%", md: "50%", lg: "30%" }} 
//                 m="auto" 
//                 p={"0 20px 60px 20px"} 
//                 borderWidth="2px" 
//                 borderRadius="5px"
//             >
//                 <Box>
//                     <Image src={xcapital_logo} m="auto" h="150px" w="150px"/>
//                 </Box>
//                 <Text color="white" fontSize={{ base: "24px", md: "36px" }} textAlign="center">
//                     Welcome !
//                 </Text>
//                 <Text color={"#ffce30"} fontSize={{ base: "18px", md: "24px" }} textAlign="center" fontWeight="bold">
//                     Login to X Capital Terminal
//                 </Text>
//                 <Box color="white">
//                     <FormControl>
//                         <FormLabel>Email</FormLabel>
//                         <Input type="email" placeholder='Email'/>
//                     </FormControl>
//                     <FormControl mt={5} mb={10}>
//                         <FormLabel>Password</FormLabel>
//                         <Input type="password" placeholder='Password'/>
//                     </FormControl>
//                     <Button width="100%" backgroundColor={"#393939"} color="white"
//                             _hover={{backgroundColor: "#ffce30", color: "#000"}}
//                             onClick={() => navigate("/client/x-capital-terminal/dashboard")}>
//                         Login
//                     </Button>
//                     <Box textAlign="center">
//                         <Link mt={5} color={"#ffce30"} fontSize="12px" textDecoration="underline">
//                             Forgot password?
//                         </Link>
//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }

// export default LoginPage;
