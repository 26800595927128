import { getAuthConfig } from "../actions/config";

export const deleteSession=async()=>{
    const config = getAuthConfig();
    const response=await fetch(`${process.env.REACT_APP_API_URL}/fsession`,{
        method:"GET",
        credentials:"include",
        ...config
    })
    await response.json().then((data)=>{
        console.log(data.msg);
    })
}