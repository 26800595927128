import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Flex, Text, Spinner } from '@chakra-ui/react';

const EarningsCalendar = () => {
    const [earningsData, setEarningsData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEarnings = async () => {
            const API_TOKEN = process.env.REACT_APP_BEARER_TOKEN;
            try {
                const earningsResponse = await axios.get('https://api.xcapitalterminal.com/api/v1/macro-data/earning-calendar', {
                    headers: {
                        Authorization: `Bearer ${API_TOKEN}`,
                    },
                });
                const earningsRawData = earningsResponse.data;
            //     const earningsRawData = [
            //       {
            //           "id": 3,
            //           "company": 71,
            //           "date": "2024-12-26",
            //           "before_open": true,
            //           "after_close": true
            //       },
            //       {
            //           "id": 4,
            //           "company": 107,
            //           "date": "2024-12-28",
            //           "before_open": true,
            //           "after_close": false
            //       },
            //       {
            //           "id": 5,
            //           "company": 96,
            //           "date": "2024-12-29",
            //           "before_open": true,
            //           "after_close": true
            //       },
            //   ];
                const companyResponse = await axios.get('https://api.xcapitalterminal.com/api/v1/market-data/companies', {
                    headers: {
                        Authorization: `Bearer ${API_TOKEN}`,
                    },
                });
                const companyRawData = companyResponse.data;

                const companyMapping = {};

                companyRawData.forEach((company) => {
                    companyMapping[company.id] = company.name;
                });

                const transformedData = transformData(earningsRawData, companyMapping);
                // console.log("Transformed Data:", transformedData); // Debugging
                setEarningsData(transformedData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err); // Debugging
                setError('Failed to fetch earnings data.');
                setLoading(false);
            }
        };

        fetchEarnings();
    }, []);

    const transformData = (data, companyMapping) => {
        const result = {};
        data.forEach(({ date, company }) => {
            const day = new Date(date).toLocaleDateString('en-US', { weekday: 'long' });

            if (!result[day]) {
                result[day] = [];
            }

            const companyName = companyMapping[company] || `Company ${company}`;
            result[day].push(companyName);
        });
        return result;
    };

    if (loading) return <Spinner size="xl" color="white" />;
    if (error) return <Text color="red">{error}</Text>;

    // console.log("Earnings Data State:", earningsData); // Debugging

    return (
        <Box bg="black" color="white" m="auto">
            <Box bg="#1f1f1f" textAlign="center" w="100%" h="40px" p={2} mt="40px" fontSize="18px" color="white">
                Earnings Calendar
            </Box>
            <Flex bgColor="#0A0A0A" flexWrap="wrap">
                {Object.keys(earningsData).map((day) => (
                    <Box key={day} flex="1" minW="250px" minH="250px" border="1px" borderColor="gray.600" m={2} borderRadius="md">
                        <Text fontSize="lg" fontWeight="bold" textAlign="center" bg="black" py={2} borderRadius="md">
                            {day}
                        </Text>
                        <Box p={2}>
                            {earningsData[day]?.map((company, index) => (
                                <Flex justifyContent="center" alignItems="center" key={index} mt={2}>
                                    <Text ml={2}>{company}</Text>
                                </Flex>
                            )) || <Text>No Data Available</Text>}
                        </Box>
                    </Box>
                ))}
            </Flex>
        </Box>
    );
};

export default EarningsCalendar;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Box, Flex, Text, Spinner } from '@chakra-ui/react';

// const EarningsCalendar = () => {
//     const [earningsData, setEarningsData] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchEarnings = async () => {
//             const API_TOKEN = process.env.REACT_APP_BEARER_TOKEN
//             try {
//                 const earningsResponse = await axios.get('https://api.xcapitalterminal.com/api/v1/macro-data/earning-calendar',{
//                     headers: {
//                       Authorization: `Bearer ${API_TOKEN}`,
//                     },
//                   });
//                 const earningsRawData = earningsResponse.data;
//             //     const earningsRawData = [
//             //       {
//             //           "id": 3,
//             //           "company": 71,
//             //           "date": "2024-12-28",
//             //           "before_open": true,
//             //           "after_close": true
//             //       },
//             //       {
//             //           "id": 4,
//             //           "company": 107,
//             //           "date": "2024-12-28",
//             //           "before_open": true,
//             //           "after_close": false
//             //       },
//             //       {
//             //           "id": 5,
//             //           "company": 96,
//             //           "date": "2024-12-29",
//             //           "before_open": true,
//             //           "after_close": true
//             //       },
//             //   ];
//                 const companyResponse = await axios.get('https://api.xcapitalterminal.com/api/v1/market-data/companies',{
//                     headers: {
//                       Authorization: `Bearer ${API_TOKEN}`,
//                     },
//                   });
//                   const companyRawData = companyResponse.data;

//                   const companyMapping = {};

//                   companyRawData.forEach((company) => {
//                       companyMapping[company.id] = company.name;
//                   });
//                 //   setCompanyMap(companyMapping);
                

//                 const transformedData = transformData(earningsRawData, companyMapping);
//                 setEarningsData(transformedData);
//                 setLoading(false);
//             } catch (err) {
//                 setError('Failed to fetch earnings data.');
//                 setLoading(false);
//             }
//         };

//         fetchEarnings();
//     }, []);


//     const transformData = (data, companyMapping) => {
//         const result = {};
//         data.forEach(({ date, before_open, after_close, company }) => {
//             const day = new Date(date).toLocaleDateString('en-US', { weekday: 'long' });

//             if (!result[day]) {
//                 result[day] = { beforeOpen: [], afterClose: [] };
//             }

//             const companyName = companyMapping[company] || `Company ${company}`;
//             if (before_open) {
//                 result[day].beforeOpen.push(companyName);
//             }

//             if (after_close) {
//                 result[day].afterClose.push(companyName);
//             }
//         });
//         return result;
//     };

//     if (loading) return <Spinner size="xl" color="white" />;
//     if (error) return <Text color="red">{error}</Text>;

//     return (
//         <Box bg="black" color="white" m="auto">
//             <Box bg="#1f1f1f" textAlign="center" w="100%" h="40px" p={2} mt="40px" fontSize="18px" color="white">
//                 Earnings Calendar
//             </Box>
//             <Flex bgColor="#0A0A0A" flexWrap="wrap">
//                 {Object.keys(earningsData).map((day) => (
//                     <Box key={day} flex="1" minW="250px" minH="250px" border="1px" borderColor="gray.600" m={2} borderRadius="md">
//                         <Text fontSize="lg" fontWeight="bold" textAlign="center" bg="black" py={2} borderRadius="md">
//                             {day}
//                         </Text>
//                         <Flex direction="row">
//                             <Box p={2} width="50%">
//                                 <Text fontSize="12px" fontWeight="bold" textAlign="center">
//                                     Before Open
//                                 </Text>
//                                 {earningsData[day].beforeOpen.map((company, index) => (
//                                     <Flex justifyContent="center" alignItems="center" key={index} mt={2}>
//                                         <Text ml={2}>{company}</Text>
//                                     </Flex>
//                                 ))}
//                             </Box>
//                             <Box width="0.5%" height="auto" bgColor="white"></Box>
//                             <Box p={2} width="50%">
//                                 <Text fontSize="12px" fontWeight="bold" textAlign="center">
//                                     After Close
//                                 </Text>
//                                 {earningsData[day].afterClose.map((company, index) => (
//                                     <Flex justifyContent="center" alignItems="center" key={index} mt={2}>
//                                         <Text ml={2}>{company}</Text>
//                                     </Flex>
//                                 ))}
//                             </Box>
//                         </Flex>
//                     </Box>
//                 ))}
//             </Flex>
//         </Box>
//     );
// };

// export default EarningsCalendar;

